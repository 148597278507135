$(document).ready(function() {
	$('.navigation__link').on('click', function (event) {
		event.preventDefault();

		if ($('navigation').hasClass('navigation--show')) {
			console.log('hi');
			$('.navigation').removeClass('navigation--show');

			$('html, body').animate({
				scrollTop: $($.attr(this, 'href')).offset().top
			}, 500);
		} else {
			$('html, body').animate({
				scrollTop: $($.attr(this, 'href')).offset().top
			}, 500);
		}
	});
});